/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import AOS from 'aos';
import Cookies from 'js-cookie';

// Animate On Scroll
AOS.init({
	once: true,
	easing: 'ease-in-sine'
});

// Observe navigation scroll
if ('IntersectionObserver' in window) {
	new IntersectionObserver(function (entries) {
		entries.forEach(function (entry) {
			entry.target.closest('header').classList[entry.isIntersecting ? 'add' : 'remove']('top');
		});
	}).observe(document.querySelector('header .logo'));
}

// Focus search input
var searchToggler = document.getElementById('search'), searchInput = document.getElementById('q');
if (searchToggler && searchInput) {
	searchToggler.addEventListener('change', function () {
		if (this.checked) {
			searchInput.focus();
		}
	});
}

// Swiper
var swipers = document.querySelectorAll('.swiper-container');
if (swipers.length) {
	(async function () {
		const Swiper = (await import('swiper')).default;

		new Swiper('.swiper-container.slider', {
			slidesPerView: 'auto',
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			}
		});

		new Swiper('.swiper-container.slideshow', {
			effect: 'fade',
			loop: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			}
		});
	})();
}

// lightGallery
var galleries = document.querySelectorAll('.gallery-block, .video');
if (galleries.length) {
	(async function () {
		await import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js');
		await import(/* webpackChunkName: "lightgallery" */ 'lg-video.js');

		galleries.forEach(function (el) {
			lightGallery(el, {
				selector: 'a',
				download: false,
				videoMaxWidth: '1920px'
			});
		});
	})();
}

// Toasts
if (window.toasts && window.toasts.length > 0) {
	var cookieName = 'toasts',
		ids = JSON.parse(Cookies.get(cookieName) || '[]'),
		ignoreId = function (id) {
			ids.push(id);
			Cookies.set(cookieName, JSON.stringify(ids));
		};

	var toasts = window.toasts.filter(function (toast) {
		return ids.indexOf(toast.id) < 0;
	});

	if (toasts.length > 0) {
		(async function () {
			const Toastify = (await import('toastify-js')).default;

			toasts.forEach(function (toast) {
				Toastify({
					text: toast.text,
					duration: 8000,
					gravity: 'top',
					position: 'right',
					stopOnFocus: true,
					onClick: function () {
						ignoreId(toast.id);
						window.location = toast.url;
					},
					callback: function () {
						ignoreId(toast.id);
					}
				}).showToast();
			});
		})();
	}
}